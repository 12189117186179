import React from 'react';
import moment from 'moment';
import FeedbackCollector from '../components/FeedbackCollector';
import classes from './Home.module.css';

export default function Home() {
  return (
    <div className={classes.container}>
      <div style={{ overflow: 'auto' }}>
        <FeedbackCollector />
      </div>
    </div>
  );
}
