// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".jHGIEtZ8Cano7oMqqBoS {\n  padding-top: 30px;\n  background-color: #3e7aa7;\n  height: 100%;\n}\n\n@media (max-width: 500px) {\n  .jHGIEtZ8Cano7oMqqBoS {\n    padding-top: 0;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Home.module.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE;IACE,cAAc;EAChB;AACF","sourcesContent":[".container {\n  padding-top: 30px;\n  background-color: #3e7aa7;\n  height: 100%;\n}\n\n@media (max-width: 500px) {\n  .container {\n    padding-top: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "jHGIEtZ8Cano7oMqqBoS"
};
export default ___CSS_LOADER_EXPORT___;
