import React, { useEffect, useRef, useState } from 'react';
import { BASE_URL } from '../utilities/constants';
import { persistedUseState } from '../utilities/persistedUseState';
import classes from './FeedbackCollector.module.css';

function makeEventSetter(callback) {
  return (event) => {
    callback(event.target.value);
  }
}

function getQueryParamObject() {
  const params = new URLSearchParams(window.location.search);
  const queryParamObject = {};
  for (const [key, value] of params) {
    queryParamObject[key] = value;
  }
  return queryParamObject;
}

export default function FeedbackCollector(props) {
  const [feedback, setFeedback] = persistedUseState('');
  const [name, setName] = persistedUseState('name', '');
  const [phone, setPhone] = persistedUseState('phone', '');
  const [email, setEmail] = persistedUseState('email', '');
  const [businessId, setBusinessId] = persistedUseState('businessId', '');
  const [submitStatus, setSubmitStatus] = useState('');
  const [submitFailed, setSubmitFailed] = useState(false);
  const statusRef = useRef();
  const conference = getQueryParamObject()?.conference;

  useEffect(() => {
    statusRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [submitStatus])

  const submit = async () => {
    setSubmitFailed(false);
    setSubmitStatus('');

    if (feedback && email) {
      const result = await fetch(BASE_URL + '/v3/submitConferenceFeedback', { method: 'POST', body: JSON.stringify({ name, phone, email, business_id: businessId, message: feedback, is_public: false, conference }), headers: { 'Content-Type': 'application/json' } });
      if (result.ok) {
        setSubmitStatus(`Thank you for your feedback! You said "${feedback}"`);
        setSubmitFailed(false);
        setFeedback('');
      } else {
        const response = await result.text();
        setSubmitStatus('' + result.status + ': ' + response);
        setSubmitFailed(true);
      }
    } else {
      setSubmitStatus('Please enter an email and a message.');
      setSubmitFailed(true);
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div className={classes.inner}>
        <div style={{ padding: 20, backgroundColor: '#13364e', color: '#fff' }}>
          <h2 style={{ fontWeight: '300' }}>{conference ? `${conference} Feedback` : 'Submit Feedback'}</h2>
          <div style={{ fontSize: 14 }}>
            -By <a href="https://rossware.com">Rossware Computing</a> <span style={{ marginLeft: 10, opacity: .7 }}>* is required</span>
          </div>
        </div>
        <div style={{ padding: 10 }}>
          <div className={classes.section}>
            <h3 className={classes.subtitle}>Your Name</h3>
            <input className={classes.basicInput} value={name} onChange={makeEventSetter(setName)}/>
          </div>
          <div className={classes.section}>
            <h3 className={classes.subtitle}>Phone Number</h3>
            <input type="tel" className={classes.basicInput} value={phone} onChange={makeEventSetter(setPhone)}/>
          </div>
          <div className={classes.section}>
            <h3 className={classes.subtitle}>Email Address*</h3>
            <input type="email" className={classes.basicInput} value={email} onChange={makeEventSetter(setEmail)}/>
          </div>
          <div className={classes.section}>
            <h3 className={classes.subtitle}>Rossware Business ID</h3>
            <input type="tel" className={classes.basicInput} placeholder="xxxx" value={businessId} onChange={makeEventSetter(setBusinessId)}/>
          </div>
          <div className={classes.section}>
            <h3 className={classes.subtitle}>Feedback*</h3>
            <textarea className={classes.basicTextArea} value={feedback} onChange={makeEventSetter(setFeedback)}/>
          </div>
          <div style={{ marginTop: 5, padding: 10, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
            <button onClick={submit} style={{ padding: '8px 22px', border: 'none', boxShadow: '2px 2px 5px rgba(0,0,0,.8)', borderRadius: 6, backgroundColor: '#3ea763', fontWeight: '300', fontSize: 14, color: '#fff', cursor: 'pointer' }}>Submit</button>
          </div>
        </div>
      </div>
      {!!submitStatus && <div ref={statusRef} style={{ marginTop: 35, marginBottom: 20, padding: 10, display: 'flex', flexDirection: 'row', justifyContent: 'center', color: '#fff', backgroundColor: submitFailed ? '#F92C85' : '#333', justifyContent: 'space-between', alignItems: 'center' }}>{submitStatus}<div onClick={() => setSubmitStatus('')} style={{ width: 30, height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff', color: '#f00', borderRadius: '50%', marginLeft: 15, cursor: 'pointer' }}>X</div></div>}
    </div>
  );
}
